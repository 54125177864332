.tableCapture > tbody > tr > td:first-child{
  width: auto!important;
}

.tableCapture > tbody > tr > td:nth-child(2){
  width: auto!important;
}

.tableCapture > tbody > tr > td:nth-child(6){
  text-align: center!important;
}