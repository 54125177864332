.MuiListSubheader-sticky{
  position: relative!important;
  background-color: #fff!important;
  color: #C15028!important;
}

.titleList{
  font-weight: bold!important;
  font-size: 16px!important;
  color: #fff!important;
  background-color: #C15028!important;
}

.flagState{
  display: none!important;
}

.MuiMenu-list > .MuiListItem-gutters > div.flagState{
  display: block!important;
}